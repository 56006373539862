import { Controller } from 'stimulus'
import { enableForm, disableForm } from 'src/utils'

export default class extends Controller {
  static targets = ['externalId', 'title', "submitButton"]

  connect() {
    this.validateForm()
  }

  validateForm() {
    const externalIdValue = this.externalIdTarget.value.trim()
    const titleValue = this.titleTarget.value.trim()

    this.submitButtonTarget.disabled = !(externalIdValue && titleValue)
  }
}