import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["video", "overlay"]
  static values = {
    initPlayer: Boolean,
    videoId: Number
  }

  connect() {
    this.originalHTML = this.element.innerHTML
    this.lastPlayTime = 0
    this.wasPlaying = false

    if (this.initPlayerValue) {
      this.initPlayer()
    }
  }

  dispatchVjsInitialized() {
    const vjsInitialized = new CustomEvent('vjsInitialized', {
      detail: { player: this.player },
    })

    window.dispatchEvent(vjsInitialized)
  }

  pause() {
    if (this.player && !this.player.isDisposed()) {
      this.player.pause()
    }
  }

  disconnect() {
    if (this.player) {
      this.player.dispose()
    }
  }

  disposePlayer() {
    this.savePlayerState()
    if (this.player) {
      this.player.dispose()
      this.reinjectVideoTag()
    }
  }

  reinjectVideoTag() {
    this.element.innerHTML = this.originalHTML
  }

  savePlayerState() {
    if (this.player && !this.player.isDisposed()) {
      this.lastPlayTime = this.player.currentTime()
      this.wasPlaying = !this.player.paused()
    }
  }

  applyRanges() {
    const params = new URLSearchParams(window.location.search);
    const videosettings = params.get('videosettings');

    this.loop = params.get('loop');

    if (!videosettings) { return; }

    this.ranges = videosettings.split('_').map(range => range.split('-'));
    this.currentRange = 0;
    //
    this.player.currentTime(this.ranges[this.currentRange][0]);
    //
    this.player.on('timeupdate', this.onTimeUpdate);
    this.player.on('play', this.onPlay)
  }


  onTimeUpdate = () => {
    if (this.player.paused()) { return; }

    if (this.player.currentTime() >= this.ranges[this.currentRange][1]) {
      this.goToNextRange();
    }
  }

  onPlay = () => {
    if (this.player.currentTime() >= this.ranges.at(-1)[1]) {
      this.loopVideo()
    }
  }

  goToNextRange = () => {
    this.currentRange += 1;

    if (this.currentRange === this.ranges.length) {
      this.handleEndOfRanges();
    } else {
      this.player.currentTime(this.ranges[this.currentRange][0]);
    }
  }

  handleEndOfRanges = () => {
    this.loop === '1' ? this.loopVideo() : this.player.pause();
  }

  loopVideo = () => {
    this.currentRange = 0;
    this.player.currentTime(this.ranges[this.currentRange][0])
  }
}
