import { Controller } from "stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ['list', 'allMobileCheckbox', 'allDesktopCheckbox', 'language', 'desktop', 'desktopSelect', 'mobileSelect']

  connect() {
    this.updateSelectAllCheckbox();

    this.languageMenuButton = document.getElementById('languageMenuButton');
    this.languageMenu = document.getElementById('languageMenu');

    this.languageMenuButton.addEventListener('click', () => {
      this.toggleMenu();
    });

    document.addEventListener('click', (event) => {
      if (!this.element.contains(event.target) && this.languageMenu.classList.contains('block')) {
        this.hideMenu();
      }
    });
  }

  get visibleCategoryTargets() {
    if (window.getComputedStyle(this.desktopTarget).display === 'none') {
      return Array.from(this.mobileSelectTargets)
    }else{
      return Array.from(this.desktopSelectTargets)
    }
  }

  get allCheckboxTargets() {
    if (window.getComputedStyle(this.desktopTarget).display === 'none') {
      return this.allMobileCheckboxTarget
    }else{
      return this.allDesktopCheckboxTarget
    }
  }

  toggleMenu() {
    this.languageMenu.classList.toggle('hidden');
    this.languageMenu.classList.toggle('block');
  }

  hideMenu() {
    this.languageMenu.classList.add('hidden');
    this.languageMenu.classList.remove('block');
  }

  toggleAll(event) {
    const isChecked = event.target.checked;
    this.visibleCategoryTargets.forEach((checkbox) => {
      checkbox.checked = isChecked;
    });
  }

  updateSelectAllCheckbox() {
    this.allCheckboxTargets.checked = this.visibleCategoryTargets.every((checkbox) => checkbox.checked);
  }

  checkSelectAll() {
    this.updateSelectAllCheckbox();
  }

  async updateVideos(){
    const selectedCategories = this.visibleCategoryTargets.filter(checkbox => checkbox.checked).map(checkbox => checkbox.value)

    const categoryIdsString = selectedCategories.join(',');

    const params = new URLSearchParams();
    params.append('category_ids', categoryIdsString);

    const selectedLanguage = this.languageTarget.value;
    params.append('lang', selectedLanguage);

    const baseUrl = '/training/videos/filter_by_categories';
    const url = `${baseUrl}?${params.toString()}`;

    try {
      const response = await get(url)

      if (response.ok) {
        this.listTarget.innerHTML = await response.html
      } else {
        console.error("Failed to load videos:", response)
      }
    } catch (error) {
      console.error("Failed to load videos:", error)
    }
  }
}
