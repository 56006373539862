

/**
 * Registers a play event for a video.
 * @async
 * @function Send a http rquest to the server to register a play event for a video.
 * @param {number|string} videoId - The ID of the video being played.
 * @returns {Promise<void>} - A Promise that resolves when the play event has been registered.
 */

const registerPlayEvent = async function (videoId)  {
  if(!videoId){
    console.warn("Not videoID provided to registerPlayEvent");
    return;
  }

  const url = `/training/videos/${videoId}/played`;
  return fetch(url, { method: 'POST', credentials: 'same-origin' });
}

export { registerPlayEvent };
