import { Controller } from "stimulus";

export default class extends Controller {
  deleteSidebarState() {
    sessionStorage.removeItem('sidebarHidden')
  }

  clearSession() {
    sessionStorage.clear();
  }
}