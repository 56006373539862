import { Controller } from "stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["select", "list"]

  async changeLanguage() {
    const lang = this.selectTarget.value
    const url = `/training/categories/update_by_language?lang=${lang}`

    try {
      const response = await get(url)

      if (response.ok) {
        this.listTarget.innerHTML = await response.html
      } else {
        console.error("Failed to load categories:", response)
      }
    } catch (error) {
      console.error("Failed to load categories:", error)
    }
  }
}