import videojs from "video.js"
import 'videojs-youtube'

import VideojsController from "./videojs_controller";

export default class extends VideojsController {
  initPlayer(){
    if (this.player && !this.player.isDisposed()) {
      return
    }

    if (!this.player || this.player.isDisposed()) {
      this.player = videojs(this.videoTarget)
      if (this.initPlayerValue === false){
        this.player.controlBar.hide()
      }
    }

    this.applyRanges()

    this.player.ready(() => {
      this.dispatchVjsInitialized()
    })
  }

  play() {
    this.initPlayer()

    if (this.lastPlayTime > 0) {
      this.player.ready(() => {
        this.player.on('loadedmetadata', () => {
          this.player.currentTime(this.lastPlayTime)
          this.player.play()
        })
      })
    }else{
      this.player.play()
    }
  }
}
