//  Generates a file signature to send to a remote server
export function getPresignedSignature(file) {
  return fetch('/blobs', {
    method: 'POST',
    headers: {
      'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
      'X-Requested-With': 'XMLHttpRequest',        
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'same-origin',
    body: JSON.stringify({ file: { name: file.name, type: file.type, size: file.size } })
  })
  .then(response => response.json())
  .catch(error => console.log(error))
}

//  Send the file to a server and update the progress bar
export function getXHRRequest(file, data, element) {
  const xhr = new XMLHttpRequest()
  xhr.data = data

  xhr.onerror = (event) => {
    alert("We're sorry. An error looks to have occured.")

    return event
  }

  xhr.open('PUT', data.direct_upload_url, true)
  xhr.setRequestHeader('Content-Type', file.type)

  //  Remember to xhr.send(file) in the next function
  return xhr
}
