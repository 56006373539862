import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "source", "target", "country" ];

    connect() {
        if(this.hasSourceTarget) {
            this.populateSelect(this.sourceTarget.value);
        }
    }
    handleSelectChange() {
        this.populateSelect(this.sourceTarget.value);
        this.handleCountryChange();
    }

    populateSelect(sourceId, targetId = null) {
        // TODO: Set the get request URL dynamically
        var fullRoute = this.data.get('targetRoute');
        if (fullRoute !== null) {
            var fetchUrl = `${fullRoute}${sourceId}`;
        } else {
            var filter = `?filter=${this.data.get('targetParams')}`;
            var fetchUrl = `/${this.data.get('sourceRoutePart')}/${sourceId}/${this.data.get('targetRoutePart')}/${filter}`
        }

        fetch(fetchUrl, {
            credentials: 'same-origin'
        })
        .then(response => response.json())
        .then(data => {
            const selectBox = this.targetTarget;
            selectBox.innerHTML = '';

            // Include blank option with label "Select sales rep..."
            const blankOpt1 = document.createElement('option');
            blankOpt1.value = '';
            blankOpt1.innerHTML = 'Select sales rep...';
            selectBox.appendChild(blankOpt1);

            // Include an additional empty line with a label attribute
            const blankOpt2 = document.createElement('option');
            blankOpt2.value = '';
            blankOpt2.setAttribute('label', ' ');
            selectBox.appendChild(blankOpt2);


            data.forEach(item => {
                const opt = document.createElement('option');
                opt.value = item.id || item[1];
                opt.innerHTML = item[this.data.get('displayAttribute')] || item[0];
                opt.selected = parseInt(targetId) === item.id;
                selectBox.appendChild(opt);
            });
        });
    }

    handleCountryChange() {
        if (this.hasCountryTarget) {
            this.countryTarget.value = this.sourceTarget.value;
        }
    }
}
