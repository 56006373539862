import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["answer", "input"]

  toggleAnswer(event) {
    const answerElement = event.currentTarget;
    const input = answerElement.querySelector("[data-training--elements--question-target='input']");

    // Toggle the selected state
    const isSelected = input.value === 'true';
    input.value = isSelected ? 'false' : 'true';

    // Update the visual state
    this.updateVisualState(answerElement, !isSelected);
  }

  updateVisualState(answerElement, isSelected) {
    if (isSelected) {
      answerElement.classList.remove('hover:bg-gray-50');
      answerElement.classList.add('bg-sh-light-yellow', 'bg-opacity-50', 'border-sh-light-yellow', 'hover:bg-sh-light-yellow');
    } else {
      answerElement.classList.remove('bg-sh-light-yellow', 'bg-opacity-50', 'border-sh-light-yellow', 'hover:bg-sh-light-yellow');
      answerElement.classList.add('hover:bg-gray-50');
    }
  }
}