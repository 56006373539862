import { Controller } from "stimulus";
import { enter, leave, toggle } from "el-transition";

export default class extends Controller {
  static targets = ["mobileDropdown", "desktopDropdown"]

  toggleDesktopDropdown(){
    toggle(this.desktopDropdownTarget);
  }

  toggleMobileDropdown(){
    toggle(this.mobileDropdownTarget);
  }
}