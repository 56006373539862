import videojs from "video.js"
import { registerPlayEvent } from "../../src/video_utils";
import { post } from "@rails/request.js";

import VideojsController from "./videojs_controller";

export default class extends VideojsController {
  initPlayer(){
    if (this.player && !this.player.isDisposed()) {
      return
    }

    if (!this.player || this.player.isDisposed()) {
      this.player = videojs(this.videoTarget)

      this.player.src({
        src: this.videoSource(),
        type: this.videoType(),
        withCredentials: true
      })

      this.player.ready(() => {
        this.dispatchVjsInitialized()
      })

      this.applyRanges()

      // Recovery the last play time
      if (this.lastPlayTime > 0) {
        this.player.currentTime(this.lastPlayTime)
        if (this.wasPlaying) {
          this.player.play()
        }
      }
    }
  }



  sourceElement() {
    return this.element.querySelector('source')
  }

  videoSource() {
    return this.sourceElement().getAttribute('src')
  }

  videoType() {
    return this.sourceElement().getAttribute('type')
  }

  async play() {
    await this.signCloudFrontCookie(this.videoIdValue)

    this.initPlayer()

    await registerPlayEvent(this.videoIdValue);
    this.player.play()
  }

  async signCloudFrontCookie(videoId) {
    const URL = `/aws/cloud_front/signed_cookies/${videoId}`;

    const response = await post(URL)
  }
}
