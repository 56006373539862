import SliceVideoController from './slice_video_controller'
import { post } from "@rails/request.js";
import videojs from "video.js"

export default class extends SliceVideoController {

  async initPlayer(){
    if (this.player && !this.player.isDisposed()) {
      return
    }

    if (!this.player || this.player.isDisposed()) {
      this.originalHTML = this.element.innerHTML
      await this.signCloudFrontCookie(this.videoIdValue)

      this.player = videojs(this.videoTarget)
      this.player.src({
        src: this.videoSource(),
        type: this.videoType(),
        withCredentials: true
      })

      this.player.controlBar.hide();
      this.getRangeSliderController().setVideoInfo()
      this.applyRanges()
    }
  }

  async signCloudFrontCookie(videoId) {
    const URL = `/aws/cloud_front/signed_cookies/${videoId}`;

    const response = await post(URL)
  }

  sourceElement() {
    return this.element.querySelector('source')
  }

  videoSource() {
    return this.sourceElement().getAttribute('src')
  }

  videoType() {
    return this.sourceElement().getAttribute('type')
  }
}
