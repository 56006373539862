import { Controller } from "stimulus";
import { enter, leave, toggle } from "el-transition";

export default class extends Controller {
  static targets = ["mobileFilter"]

  openMobileFilter(){
    enter(this.mobileFilterTarget);
  }

  closeMobileFilter(){
    leave(this.mobileFilterTarget);
  }
}