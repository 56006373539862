import SliceVideoController from './slice_video_controller'
import videojs from "video.js"

export default class extends SliceVideoController {
  initPlayer(){
    if (this.player && !this.player.isDisposed()) {
      return
    }

    if (!this.player || this.player.isDisposed()) {
      this.player = videojs(this.videoTarget)

      this.player.controlBar.hide();
      this.getRangeSliderController().setVideoInfo()
      this.applyRanges()
    }
  }
}
