import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['container']
  static values = {
    youtubeVideo: Boolean
  }

  connect() {
    this.setupEventListeners()
  }

  setupEventListeners() {
    window.addEventListener('keyup', event => {
      if (event.key === 'Escape') this.closeVideo()
    })

    this.containerTarget.querySelector('.close').addEventListener('click', () => this.closeVideo())
  }

  closeVideo() {
    this.getVideoJsController().disposePlayer()
    this.containerTarget.classList.remove('show')
  }

  play() {
    this.containerTarget.classList.add('show');

    this.getVideoJsController().play();
  }

  getVideoJsController() {
    const controllerType = this.youtubeVideoValue ? 'video--videojs-youtube' : 'video--videojs-uploaded';

    const videoJsElement = this.element.querySelector(`[data-controller='${controllerType}']`);
    return this.application.getControllerForElementAndIdentifier(videoJsElement, controllerType);
  }
}