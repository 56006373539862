import { Controller } from 'stimulus'
import { wasVideoViewed } from '../../src/utils'
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = ['videoJs', 'overlay']
  static values = {
    completeUrl: String,
  }

  start = ({ detail: { player } }) => {
    if (!player) return

    this.player = player

    this.player.on('timeupdate', this.videoHandler)
    this.player.controlBar.progressControl.disable()

    this.player.on('play', this.hideOverlay.bind(this));
    this.player.on('pause', this.showOverlay.bind(this));
    this.player.on('ended', this.showOverlay.bind(this));
  }

  hideOverlay() {
    this.overlayTarget.classList.add('opacity-0');
  }

  showOverlay() {
    this.overlayTarget.classList.remove('opacity-0');
  }

  disconnect() {
    if (this.player) this.player.off('timeupdate', this.videoHandler)
  }

  videoHandler = (_event) => {
    if (this.player.paused()) return

    const seconds = Math.floor(this.player.currentTime())
    const duration = this.player.duration()
    if (wasVideoViewed(duration, seconds)){
      this.completeVideoElement()
    }
  }

  async completeVideoElement() {
    if (this.hasCompleteUrlValue && !this.videoViewed) {
      this.videoViewed = true

      await post(this.completeUrlValue, { responseKind: 'turbo-stream' })
    }
  }
}
