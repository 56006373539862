import { Controller } from 'stimulus'
import vjs from 'video.js'

export default class extends Controller {
  static targets = ['fromSlider', 'toSlider', 'fromInput', 'toInput', 'newSlice']
  static values= {
    videoType: String
  }
  connect() {
    this.fillSlider()
    this.setToggleAccessible()
  }

  setVideoInfo(){
    if (this.videoTypeValue === 'UploadedVideo') {
      this.player = vjs(document.querySelector('[data-training--video-player--slice-uploaded-video-target]'))
    }else{
      this.player = vjs(document.querySelector('[data-training--video-player--slice-youtube-video-target]'))
    }

    this.range = this.sliderPosition()
    this.player.on('timeupdate', this.onTimeUpdate)
    this.player.on('play', this.onPlay)
  }

  controlFromInput() {
    const [from, to] = this.inputPosition()
    this.control('from', from, to)
  }

  controlFromSlider() {
    const [from, to] = this.sliderPosition()
    this.control('from', from, to)
  }

  controlToInput() {
    const [from, to] = this.inputPosition()
    this.control('to', from, to)
  }

  controlToSlider() {
    const [from, to] = this.sliderPosition()
    this.control('to', from, to)
  }

  control(inputType, from, to) {
    this.selectSlice()

    if ((inputType === 'from' && from > to) || (inputType === 'to' && from > to)) {
      const value =  inputType === 'from' ? to : from

      this.updateSliceValues(value, value)
      this.updateSliderAndInputValues(this.fromSliderTarget, this.fromInputTarget, value)
      this.updateSliderAndInputValues(this.toSliderTarget, this.toInputTarget, value)
    }
    else {
      this.updateSliceValues(from, to)
      this.updateSliderAndInputValues(this.fromSliderTarget, this.fromInputTarget, from)
      this.updateSliderAndInputValues(this.toSliderTarget, this.toInputTarget, to)
    }

    this.fillSlider()
    this.setToggleAccessible()
    inputType === 'from' ? this.applyStartTime() : this.applyEndTime()
  }

  updateSliceValues(fromValue, toValue) {
    this.slice.setAttribute('data-start', fromValue)
    this.slice.setAttribute('data-end', toValue)
    this.sliceStart.innerHTML = fromValue
    this.sliceEnd.innerHTML = toValue
    this.sliceDuration.innerHTML = Math.max(0, toValue - fromValue)
  }

  updateSliderAndInputValues(sliderTarget, inputTarget, value) {
    sliderTarget.value = value
    inputTarget.value = value
  }

  inputPosition() {
    const from = parseInt(this.fromInputTarget.value, 10)
    const to = parseInt(this.toInputTarget.value, 10)
    return [from, to]
  }

  sliderPosition() {
    const from = parseInt(this.fromSliderTarget.value, 10)
    const to = parseInt(this.toSliderTarget.value, 10)
    return [from, to]
  }

  fillSlider() {
    const sliderColor = '#d2d2d2'
    const rangeColor = '#ffcd05'
    const rangeDistance = this.toSliderTarget.max-this.toSliderTarget.min
    const fromPosition = this.fromSliderTarget.value - this.toSliderTarget.min
    const toPosition = this.toSliderTarget.value - this.toSliderTarget.min
    this.toSliderTarget.style.background = `linear-gradient(
      to right,
      ${sliderColor} 0%,
      ${sliderColor} ${(fromPosition)/(rangeDistance)*100}%,
      ${rangeColor} ${((fromPosition)/(rangeDistance))*100}%,
      ${rangeColor} ${(toPosition)/(rangeDistance)*100}%,
      ${sliderColor} ${(toPosition)/(rangeDistance)*100}%,
      ${sliderColor} 100%)`
  }

  setToggleAccessible() {
    const toSlider = document.querySelector('#toSlider')
    if (Number(this.toSliderTarget.value) <= 0 ) {
      toSlider.style.zIndex = 2
    } else {
      toSlider.style.zIndex = 0
    }
  }

  applyStartTime() {
    this.range = this.sliderPosition()
    this.player.currentTime(this.range[0])
  }

  applyEndTime() {
    this.range = this.sliderPosition()
  }

  onTimeUpdate = () => {
    if (this.player.paused()) { return }

    if (this.player.currentTime() >= this.range[1]) {
      this.player.pause()
    }
  }

  onPlay = () => {
    if (this.player.currentTime() >= this.range[1]) {
      this.player.currentTime(this.range[0])
    }
  }

  update(e) {
    const slice = e.target.closest('.slice')

    this.updateSliderAndInputValues(this.fromSliderTarget, this.fromInputTarget, slice.dataset.start)
    this.updateSliderAndInputValues(this.toSliderTarget, this.toInputTarget, slice.dataset.end)

    this.fillSlider()
    this.setToggleAccessible()
    this.applyStartTime()
  }

  resetRange() {
    const to = this.toInputTarget.getAttribute('max')

    this.updateSliderAndInputValues(this.fromSliderTarget, this.fromInputTarget, 0)
    this.updateSliderAndInputValues(this.toSliderTarget, this.toInputTarget, to)

    this.newSliceTarget.setAttribute('data-start' , 0)
    this.newSliceTarget.querySelector('.slice-start').innerHTML = 0
    this.newSliceTarget.setAttribute('data-end' , to)
    this.newSliceTarget.querySelector('.slice-end').innerHTML = to

    this.newSliceTarget.querySelector('.slice-duration').innerHTML = to

    this.fillSlider()
    this.setToggleAccessible()
    this.applyStartTime()
  }

  selectSlice() {
    this.slice = document.querySelector(".slice.active")
    this.sliceStart = this.slice.querySelector('.slice-start')
    this.sliceEnd = this.slice.querySelector('.slice-end')
    this.sliceDuration = this.slice.querySelector('.slice-duration')
  }

  newSlice() {
    this.newSliceStart = this.newSliceTarget.querySelector('.slice-start')
    this.sliceEnd = this.slice.querySelector('.slice-end')
    this.sliceDuration = this.slice.querySelector('.slice-duration')
  }
}
