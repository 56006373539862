import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.clearSession();
  }

  clearSession() {
    sessionStorage.clear();
  }
}