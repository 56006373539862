import { Controller } from "stimulus";
import { toggle, enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["sharing", "guide", "scorm"]
  static values = {
    type: String
  }
  toggleGuide(){
    toggle(this.guideTarget);
  }

  toggleScorm(){
    toggle(this.scormTarget);
  }

  enterSharing(){
    if (this.typeValue === 'UploadedVideo'){
      this.getSliceUploadedVideoController().initPlayer()
      enter(this.sharingTarget);
    }else{
      this.getSliceYoutubeVideoController().initPlayer()
      enter(this.sharingTarget);
    }
  }

  leaveSharing(){
    if (this.typeValue === 'UploadedVideo'){
      this.getSliceUploadedVideoController().disposePlayer()
      leave(this.sharingTarget);
    }else{
      this.getSliceYoutubeVideoController().disposePlayer()
      leave(this.sharingTarget);
    }
  }

  getSliceUploadedVideoController() {
    const videoJsElement = this.element.querySelector("[data-controller='training--video-player--slice-uploaded-video']")
    return this.application.getControllerForElementAndIdentifier(videoJsElement, "training--video-player--slice-uploaded-video")
  }

  getSliceYoutubeVideoController() {
    const videoJsElement = this.element.querySelector("[data-controller='training--video-player--slice-youtube-video']")
    return this.application.getControllerForElementAndIdentifier(videoJsElement, "training--video-player--slice-youtube-video")
  }
}