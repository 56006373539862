import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["sidebar", "videoContainer", "toggleIcon", "toggleButton", "videoGrid", "mainContainer", "sidebarWrapper"]

  connect() {
    this.loadSidebarState()
  }

  toggleFilter() {
    const isHidden = this.sidebarTarget.classList.contains("lg:hidden")

    if (isHidden) {
      this.showFilter()
    } else {
      this.hideFilter()
    }

    this.saveSidebarState(!isHidden)
  }

  showFilter() {
    this.sidebarTarget.classList.remove("lg:hidden")
    this.toggleIconTarget.style.transform = "rotate(0deg)"
    this.sidebarWrapperTarget.style.width = "20%"
    this.updateToggleButtonPosition("show")
    this.updateVideoGrid(3)
  }

  hideFilter() {
    this.sidebarTarget.classList.add("lg:hidden")
    this.toggleIconTarget.style.transform = "rotate(180deg)"
    this.sidebarWrapperTarget.style.width = "40px"
    this.updateToggleButtonPosition("hide")
    this.updateVideoGrid(4)
  }

  updateVideoGrid(columns) {
    this.videoContainerTarget.classList.remove("lg:grid-cols-3", "lg:grid-cols-4")
    this.videoContainerTarget.classList.add(`lg:grid-cols-${columns}`)
  }

  updateToggleButtonPosition(state = "show") {
    if (state === "show") {
      this.toggleButtonTarget.classList.remove("left-0")
      this.toggleButtonTarget.classList.add("right-0")
    } else {
      this.toggleButtonTarget.classList.remove("right-0")
      this.toggleButtonTarget.classList.add("left-0")
    }
  }

  loadSidebarState() {
    const sidebarHidden = sessionStorage.getItem('sidebarHidden') === 'true'

    if (sidebarHidden) {
      this.hideFilter()
    } else {
      this.showFilter()
    }
  }

  saveSidebarState(hidden) {
    sessionStorage.setItem('sidebarHidden', hidden.toString())
  }
}