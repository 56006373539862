import { Controller } from 'stimulus'
import { enableForm, disableForm, isValidFileType, clearHover } from 'src/utils'
import { getPresignedSignature, getXHRRequest } from 'src/aws'

export default class extends Controller {
  static targets = ['container', 'input', 'bar', 'filename', 'status', 'amount']

  connect() {
    if (!this.inputTarget.value) disableForm(this.inputTarget.form)
  }

  upload(event) {
    clearHover(document.querySelector('.droppable'))

    const input = event.target
    const file = input.files[0]
    const player = (document.querySelector('video')) ? document.querySelector('video') : null
  
    if (isValidFileType([file], input.accept)) {
      if (player)
        player.src = URL.createObjectURL(file)

      this.filenameTarget.textContent ||= file.name
      this.containerTarget.classList.add('populated')
      this.containerTarget.querySelector('.show').style.display = 'block'
      this.containerTarget.querySelector('.progress').style.display = 'block'

      //  Direct Upload to S3
      getPresignedSignature(file)
        .then(json => getXHRRequest(file, json))
        .then(xhr => updateProgress(xhr, file, this))
        .catch(error => console.log(error))
    } else {
      alert('Invalid video format')
    }
  }
}

function updateProgress(xhr, file, form) {

  xhr.upload.onprogress = (event) => {
    let progression = (100 * event.loaded / event.total).toFixed(2)
    form.barTarget.style.width = `${progression}%`
    form.amountTarget.innerText = `${progression}%`  }

  xhr.onload = (event) => {
    if (xhr.status == '200') {
      enableForm(form.inputTarget.form)
      form.statusTarget.innerText = 'upload completed'
      form.amountTarget.innerText = ''
      form.inputTarget.value = xhr.data.uuid

      fetch(`/lms/videos/transcode/${xhr.data.uuid}`)
    }
  }

  xhr.send(file)
}
