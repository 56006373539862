import { Controller } from 'stimulus'
import { get, post } from '@rails/request.js'
import { wasVideoViewed } from '../../src/utils'

export default class extends Controller {
  static targets = ['continueCard', 'descriptionOverlay']
  static values = {
    checkPoints: Array,
    completeUrl: String,
    cursor: Number,
  }

  start = ({ detail: { player } }) => {
    if (!player) return

    this.player = player
    this.checkPoints = this.checkPointsValue.reduce((acc, seconds) => {
      acc[seconds] = false
      return acc
    }, {})

    this.updateOnCursor()

    this.player.on('timeupdate', this.videoHandler)
    this.player.on('ended', this.videoEnded)
    this.disableProgressControl();
    this.disableFullScreen();
  }

  disconnect() {
    if (this.hasVideojsTarget) {
      this.player.off('timeupdate', this.videoHandler)
      this.player.off('ended', this.videoEnded)
    }
  }

  updateOnCursor() {
    if (!isNaN(this.cursorValue)) {
      this.player.currentTime(this.cursorValue)
    }
  }

  videoHandler = (_event) => {
    if (this.player.paused()) return
    this.hideElementDescription();
    const seconds = Math.floor(this.player.currentTime())

    if (this.wasVideoViewed(seconds)) this.completeVideoElement()
    if (!this.checkPointReached(seconds)) return

    this.player.pause()
    this.checkPoints[seconds] = true
    this.showQuestion({ seconds })
  }

  wasVideoViewed(seconds) {
    if (this.hasCheckPointNotReached()) return false

    const duration = this.player.duration()

    return wasVideoViewed(duration, seconds)
  }

  hasCheckPointNotReached() {
    return Object.values(this.checkPoints).some((value) => value == false)
  }

  async completeVideoElement() {
    if (this.hasCompleteUrlValue && !this.videoViewed) {
      this.videoViewed = true

      await post(this.completeUrlValue, { responseKind: 'turbo-stream' })
    }
  }

  checkPointReached(seconds) {
    return this.checkPoints.hasOwnProperty(seconds) && !this.checkPoints[seconds]
  }

  showQuestion({ seconds, questionId, previousQuestionId, extraClass }) {
    this.disablePlayerControls()
    let question

    // hide previous question
    if (previousQuestionId) this.hideQuestion(previousQuestionId)

    // find question
    if (questionId) {
      question = this.element.querySelector(`#${questionId}`)
    } else if (seconds != null) {
      // find first question of the chekpoint
      question = this.element.querySelector(`[data-check-point-seconds="${seconds}"]`)
    }

    if (!question) return

    // show question
    question.classList.add(extraClass)
    question.classList.remove('hidden')
  }

  hideQuestion(questionId) {
    const question = this.element.querySelector(`#${questionId}`)
    if (question) question.classList.add('hidden')
  }

  continue(event) {
    const currentTarget = event.currentTarget
    const nextQuestionId = currentTarget.dataset.nextQuestion
    const feedback = currentTarget.closest('#feedback')

    const submitQuestionUrl = currentTarget.dataset.submitQuestionUrl

    if (submitQuestionUrl) post(submitQuestionUrl)

    if (nextQuestionId) {
      this.showQuestion({
        questionId: `question_${nextQuestionId}`,
        extraClass: 'transition-none',
      })
    } else {
      this.player.play()
      this.enablePlayerControls()
    }
    feedback.remove()
  }

  tryAgain(event) {
    const currentTarget = event.currentTarget
    const questionId = currentTarget.dataset.question
    const feedback = currentTarget.closest('#feedback')

    this.showQuestion({
      questionId: `question_${questionId}`,
      extraClass: 'transition-none',
    })
    feedback.remove()
  }

  async submitQuestion(event) {
    event.preventDefault()
    const form = event.target.form
    const formData = new FormData(form)
    const response = await post(form.action, {
      body: formData,
      responseKind: 'turbo-stream',
    })
    // verify if the response has headers
    if (response.headers.has('next-question-id')) {
      const nextQuestionId = response.headers.get('next-question-id')
      const currentQuestionDomId = `question_${formData.get('question_id')}`
      // if next-question-id header is not an empty string
      if (!!nextQuestionId) {
        const nextQuestionDomId = `question_${nextQuestionId}`
        this.showQuestion({
          questionId: nextQuestionDomId,
          previousQuestionId: currentQuestionDomId,
          extraClass: 'transition-none',
        })
      } else {
        // if next-question-id header is an empty string
        this.hideQuestion(currentQuestionDomId)
        this.player.play()
        this.enablePlayerControls()
      }
    }
  }

  videoEnded = (event) => {
    if (this.hasContinueCardTarget && this.wasVideoViewed(this.player.duration())) {
      this.continueCardTarget.classList.remove('hidden')
    }
  }

  disablePlayerControls() {
    this.player.controls(false);
    this.player.bigPlayButton.hide();
  }

  enablePlayerControls() {
    this.player.controls(true); // Enable controls
    this.player.bigPlayButton.show();
  }

  disableProgressControl() {
    this.player.controlBar.progressControl.disable()
  }

  disableFullScreen() {
    this.player.controlBar.fullscreenToggle.disable();
    this.player.tech_.off('dblclick'); // Don't allow fullscreen with double click
  }

  hideElementDescription() {
    this.descriptionOverlayTarget.classList.add('hidden');
  }

  async loadSummary(event) {
    event.preventDefault()
    const url = event.currentTarget.href

    try {
      const response = await get(url);

      if (response.ok) {
        const fullHTML = await response.html;
        const parser = new DOMParser();
        const doc = parser.parseFromString(fullHTML, 'text/html');

        const quizResultsSection = doc.querySelector('#quiz-results');

        if (quizResultsSection) {
          this.continueCardTarget.classList.remove('hidden');
          this.continueCardTarget.classList.add('flex');

          this.continueCardTarget.innerHTML = quizResultsSection.outerHTML;
        } else {
          console.error("Quiz results section not found in the response");
        }
      } else {
        console.error("Failed to load Quiz result:", response);
      }
    } catch (error) {
      console.error("Failed to load Quiz result:", error);
    }
  }
}
