// app/javascript/controllers/datepicker_controller.js
import { Controller } from "stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
  static targets = ['datefield']

  connect() {
    this.datefieldTargets.forEach(element => {
        this.initializeFlatpickr(element);
    });
  }

  initializeFlatpickr(element) {
    const enableTime = element.dataset.enableTime === 'true';
    const dateFormat = element.dataset.dateFormat || (enableTime ? "d-M-Y h:i K" : "d-M-Y" );

    const picker = flatpickr(element, {
          enableTime: enableTime,
          dateFormat: dateFormat,
          allowInput: true,
          clickOpens: true
      });
    this.createToggleIcon(element, picker);
  }

  createToggleIcon(element, picker) {
    const cal = document.createElement('span');
    cal.classList.add('icon', 'calendar', 'clickable', 'calendar-icon');
    cal.innerHTML = '<svg class="icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.25" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path></svg>';
    cal.addEventListener('click', () => picker.toggle());
    element.insertAdjacentElement('afterend', cal);
  }

  disconnect() {
    this.datefieldTargets.forEach(element => {
        if (element._flatpickr) {
            element._flatpickr.destroy();
        }
    });
  }
}
