import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["item"]

  connect() {
    this.currentIndex = 0
    this.totalItems = this.itemTargets.length
    this.startAutoPlay()

    this.element.addEventListener('mouseenter', this.pauseAutoPlay.bind(this))
    this.element.addEventListener('mouseleave', this.resumeAutoPlay.bind(this))
  }

  disconnect() {
    this.stopAutoPlay()
    this.element.removeEventListener('mouseenter', this.pauseAutoPlay.bind(this))
    this.element.removeEventListener('mouseleave', this.resumeAutoPlay.bind(this))
  }

  startAutoPlay() {
    this.interval = setInterval(() => this.next(), 10000)
  }

  stopAutoPlay() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  pauseAutoPlay() {
    this.stopAutoPlay()
  }

  resumeAutoPlay() {
    this.startAutoPlay()
  }

  next() {
    this.showItem((this.currentIndex + 1) % this.totalItems)
  }

  prev() {
    this.showItem((this.currentIndex - 1 + this.totalItems) % this.totalItems)
  }

  showItem(index) {
    this.itemTargets.forEach((item, i) => {
      if (i === index) {
        item.style.opacity = 1
        item.style.zIndex = 10
      } else {
        item.style.opacity = 0
        item.style.zIndex = 1
      }
    })
    this.currentIndex = index
  }
}