import VideojsController from '../../video/videojs_controller'

export default class extends VideojsController {

  disposePlayer() {
    if (this.player) {
      this.player.dispose()
      this.reinjectVideoTag()
    }
  }

  goToNextRange = () => {
    this.currentRange += 1;

    if (this.currentRange === this.ranges.length) {
      this.handleEndOfRanges();
    } else {
      this.player.currentTime(this.ranges[this.currentRange][0]);
    }
  }

  handleEndOfRanges = () => {
    this.loop === '1' ? this.loopVideo() : this.player.pause();
  }

  onTimeUpdate = () => {
    if (this.player.paused()) { return; }

    if (this.player.currentTime() >= this.ranges[this.currentRange][1]) {
      this.goToNextRange();
    }
  }

  onPlay = () => {
    if (this.player.currentTime() >= this.ranges.at(-1)[1]) {
      this.loopVideo()
    }
  }

  loopVideo = () => {
    this.currentRange = 0;
    this.player.currentTime(this.ranges[this.currentRange][0])
  }

  getRangeSliderController() {
    const rangeSliderElement = this.element.closest("[data-controller='range-slider']")
    return this.application.getControllerForElementAndIdentifier(rangeSliderElement, "range-slider")
  }
}
