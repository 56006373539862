import { Controller } from "stimulus";
import { get } from '@rails/request.js'
import TomSelect from "tom-select";

export default class extends Controller {
  static values = {
    url: String,
    labelField: String,
    valueField: String,
    searchField: String
  };

  connect() {
    this.initializeTomSelect();
  }

  disconnect() {
    this.destroyTomSelect();
  }

  initializeTomSelect() {
    if (!this.element) return;

    this.select = new TomSelect(this.element, this.getTomSelectConfig());
  }

  virtualScrollConfig() {
    const baseConfig = this.baseConfig();
    return {
      ...baseConfig,
      plugins: [...baseConfig.plugins, 'virtual_scroll'],
      preload: 'focus',
      firstUrl: (query) => this.buildUrl(query, 1),
      load: (query, callback) => this.getData(query, callback),
      render: this.renderConfig(),
      onType: (str) => this.onType(str),
      onLoad: (options) => this.onLoad()
    }
  }

  baseConfig() {
    return {
      plugins: ['dropdown_input', 'remove_button', 'clear_button'],
      valueField: this.valueFieldValue || 'value',
      labelField: this.labelFieldValue || 'text',
      searchField: [this.searchFieldValue || 'text'],
      maxOptions: null,
      hideSelected: true,
      sortField: { field: this.labelFieldValue || 'text', direction: 'asc' },
    };
  }

  getTomSelectConfig() {
    return this.urlValue ? this.virtualScrollConfig() : this.baseConfig();
  }

  async getData(query, callback) {
    const url = this.select.getUrl(query);
    const response = await get(url)

    if (response.ok) {
      const json = await response.json;
      this.handleGetResponse(json, query)
      callback(json.items)
    }
  }

  handleGetResponse(json, query) {
    if (json.has_more) {
      const nextUrl = this.buildUrl(query, json.next)
      this.select.setNextUrl(query, nextUrl);
    }
  }

  buildUrl(query, page) {
    const url = new URL(this.urlValue);
    url.searchParams.append("search", query)
    url.searchParams.append("page", page)
    return url;
  }

  renderConfig() {
    return {
      loading_more: () => this.renderLoadingMore(),
      no_more_results: () => this.renderNoMoreResults(),
      no_results: () => this.renderNoResults(),
    };
  }

  onType(str) {
    this.select.clearOptions();
    this.select.load(str);
  }

  // Tom Select have know issue when you type something and scroll down to fetch more result, it will jump to top again,
  // this code maintains the last position during scroll.
  onLoad() {
    const currentScrollPosition = this.select.dropdown_content.scrollTop;
    requestAnimationFrame(() => {
      this.select.dropdown_content.scrollTop = currentScrollPosition;
    });
  }

  renderLoadingMore() {
    return `<div class="loading-more-results"><div class="spinner"></div></div>`;
  }

  renderNoMoreResults() {
    return '<div class="no-more-results">No more results</div>';
  }

  renderNoResults() {
    return '<div class="no-results">No results found</div>'
  }

  destroyTomSelect() {
    if (this.select) {
      this.select.destroy();
    }
  }
}