import { Controller } from 'stimulus';
import { enter, leave } from 'el-transition';

export default class extends Controller {
  static targets = ["sidebar", "content", "openButton"]

  open() {
    enter(this.sidebarTarget)
    this.contentTarget.classList.remove('w-full')
    this.contentTarget.classList.add('w-[calc(100%-20rem)]') // 20rem is the width of the sidebar (w-80)
    this.openButtonTarget.classList.add('hidden')
  }

  close() {
    leave(this.sidebarTarget)
    this.contentTarget.classList.remove('w-[calc(100%-20rem)]')
    this.contentTarget.classList.add('w-full')
    this.openButtonTarget.classList.remove('hidden')
  }
}