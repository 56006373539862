import { Controller } from "stimulus";
import vjs from 'video.js'

export default class extends Controller {
  static targets = ["overlay", "player"]

  connect() {
    this.player = vjs(this.playerTarget);
    this.player.on('play', this.hideOverlay.bind(this));
    this.player.on('pause', this.showOverlay.bind(this));
    this.player.on('ended', this.showOverlay.bind(this));

    this.showOverlay();
  }

  hideOverlay() {
    this.overlayTarget.classList.add('opacity-0');
  }

  showOverlay() {
    this.overlayTarget.classList.remove('opacity-0');
  }

  disconnect() {
    if (this.player) {
      this.player.off('play', this.hideOverlay);
      this.player.off('pause', this.showOverlay);
      this.player.off('ended', this.showOverlay);
    }
  }
}