import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "tab", "content" ]
  static values = { initialTab: Number }

  connect() {
    this.showTab(this.initialTabValue)
  }

  switch(event) {
    event.preventDefault()
    const clickedTabIndex = this.tabTargets.indexOf(event.currentTarget)
    this.showTab(clickedTabIndex)
  }

  showTab(index) {
    this.tabTargets.forEach((tab, i) => {
      tab.classList.toggle("active", i === index)
    })
    this.contentTargets.forEach((content, i) => {
      content.classList.toggle("active", i === index)
    })
  }
}