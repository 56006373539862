import { addSelectionId, removeSelectionId } from 'src/utils'
import { get } from '@rails/request.js'
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['ids', 'categories', 'categoryContainer']
  static values = { videoId: Number, global: Boolean }

  connect() {
    if (this.hasCategoryContainerTarget) {
      this.categoryContainerTarget.hidden = !this.globalValue;
    }
    super.connect();
  }

  toggle(event) {
    let tag = event.target
    let id = tag.dataset.categoryId

    if (Array.from(tag.classList).includes('selected')) {
      tag.classList.remove('selected')
      removeSelectionId(id, '#uploaded_video_category_ids')
    } else {
      tag.classList.add('selected')
      addSelectionId(id, '#uploaded_video_category_ids')
    }
  }

  async updateCategories(event) {
    const categories = await this.getCategories(event.target.value, this.videoIdValue);
    if (categories) {
      // Preserve the hidden input field and only replace the divs
      this.categoriesTarget.querySelectorAll('.tag').forEach(tag => tag.remove());
      this.categoriesTarget.insertAdjacentHTML('afterbegin', categories.innerHTML);
      this.resetCategoryIds();
    }
  }

  async getCategories(language, videoId) {
    const url = `/admin/categories/categories_for_language?language=${language}&video_id=${videoId}`
    const response = await get(url);

    if (response.ok) {
      const text = await response.text;
      const parser = new DOMParser();
      const html = parser.parseFromString(text, 'text/html');
      return html.querySelector('body');
    }
  }

  toggleCategoryContainer(event) {
    const switchElement = event.detail.switchElement;
    const checkboxInput = switchElement.querySelector('[data-switch-target="checkboxInput"]');
    this.categoryContainerTarget.hidden = !checkboxInput.checked;
    this.resetCategoryIds();
  }

  resetCategoryIds() {
    this.idsTarget.value = '';
  }
}
