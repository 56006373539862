import { Controller } from 'stimulus'
import { addSelectionId, removeSelectionId } from 'src/utils'

export default class extends Controller {
  static targets = ['results', 'selection', 'input']

  connect() {
  // Mock initial search results ... this could be added to the form builder later
    let target = this.scope.element.querySelector('.chiplist-trigger.all')
    if (target) {
      target.value = '   ' // 3 spaces to trigger search
      let fakeEvent = { target: target }
      this.filterKeyStroke(fakeEvent)
      target.value = ''
    }
  }

  filterKeyStroke(event) {
    //  Build a query URL from the input and chiplist URL
    const text = event.target.value

    //  Filter the results after 2 keystrokes
    if (text.length > 2) {
      const url = new URL(event.target.dataset.searchUrl.toString())
      url.searchParams.append('search', text)
      url.searchParams.append('context', event.target.dataset['context'])

      this.filter({ detail: { url: url }})
    }
  }

  filter(event) {
    //  Get any preselected items
    const preSelected = Array.from(this.selectionTarget.querySelectorAll('.chip')).map(chip => chip.dataset.id)

    //  Query the server and update the DOM
    fetch(event.detail.url)
      .then((response) => { return response.text() })
      .then((html) => { this.resultsTarget.innerHTML = html })
      .then(() => {
        Array.from(this.resultsTarget.querySelectorAll('.chip')).forEach((chip) => {
          //  Set any result from the preSelected list as 'selected'
          if (preSelected.includes(chip.dataset.id)) chip.classList.add('selected')
        })
      })
      .catch(error => console.log(error))
  }

  selectChip(event) {
    if (this.checkingDetails(event)) return

    const chip = event.currentTarget
    let inputTarget = [this.element.dataset.object, chip.dataset.chipTarget, 'ids']
      .filter((x) =>  x !== undefined && x !== null && x !== '')
      .join('_')

    inputTarget = `#${inputTarget}`

    // If the chip is disabled, do nothing
    if (Array.from(chip.classList).includes('disabled')) {
      return
    }

    if (Array.from(chip.classList).includes('selected')) {
      this.removeChip(chip, inputTarget)
    } else if (!this.removeOnly(chip)) {
      this.addChip(chip, inputTarget)
    }
  }

  removeOnly(chip) {
    return chip.dataset.chipRemoveOnly == '1' || chip.dataset.chipRemoveOnly == 'true'
  }

  addChip(chip, inputTarget) {
    addSelectionId(chip.dataset.id, inputTarget)

    chip.classList.add('selected')
    this.selectionTarget.append(chip.cloneNode(true))
  }

  removeChip(chip, inputTarget) {
    const removeChipValue = chip.dataset.removeChip;
    if (removeChipValue === 'false') {
      return;
    }

    let selectorId = `[data-id$="${chip.dataset.id}"]`

    removeSelectionId(chip.dataset.id, inputTarget)

    this.selectionTarget.querySelector(selectorId).remove()
    document.querySelectorAll(selectorId).forEach((el) => {
      el.classList.remove('selected')
    })
  }

  clearInput(event) {
    this.inputTarget.value = ''
    this.resultsTarget.innerHTML = ''
    this.inputTarget.focus()
  }

  checkingDetails(event) {
    return event.target.id === 'bundle-details' ||
      event.target.closest('svg')?.id === 'bundle-details' ||
      event.target.classList.contains('modal') ||
      event.target.closest('.modal')
  }
}
