import DirectUploadController from "./direct_upload_controller";

export default class extends DirectUploadController {
  static targets = ['input', 'thumbnail', 'progress', 'videojs', 'preview']
  static values = { className: String }

  // When file upload is success in parent controller then this method will be called.
  // If you want to add additional behaviour after file upload then you can add in this method.
  handleUploadSuccess(blob, file) {
    super.handleUploadSuccess(blob, file)
    this.updateSelectedThumbnail(this.previewTarget)
    this.setThumbnailHiddenField(file.name)
    this.setPreviewFileName(file)
    this.updateVideoPoster(super.getImageUrl(file))
  }

  setPreviewFileName(file) {
    this.previewTarget.parentNode.setAttribute('data-thumbnail-filename', file.name)
  }

  update(event) {
    const thumbnailImage = event.currentTarget.querySelector('img');
    this.updateVideoPoster(thumbnailImage.src);
    this.updateSelectedThumbnail(event.currentTarget);
    this.setThumbnailHiddenField(this.getFileName(event));
  }

  updateVideoPoster(file_url) {
    if (this.hasVideojsTarget) {
      this.videojsTarget.player.poster(file_url);
    }
  }

  updateSelectedThumbnail(target) {
    this.removeSelectionFromThumbnails()
    if (target === this.previewTarget) {
      target.parentNode.classList.add('selected')
    } else {
      target.classList.add('selected')
    }
  }

  removeSelectionFromThumbnails() {
    this.thumbnailTargets.forEach((thumbnail) => {
      thumbnail.classList.remove('selected')
    })
  }

  setThumbnailHiddenField(fileName) {
    const className = this.classNameValue
    let hiddenField = document.querySelector(`input[name="${className}[thumbnail]"]`)

    if (!hiddenField) {
      hiddenField = this.createNewHiddenField(className)
    }

    hiddenField.setAttribute('value', fileName)
  }

  createNewHiddenField(className) {
    let hiddenField = document.createElement('input');
    hiddenField.setAttribute('type', 'hidden');
    hiddenField.setAttribute('name', `${className}[thumbnail]`);
    this.element.appendChild(hiddenField);

    return hiddenField;
  }

  getFileName(event) {
    return event.currentTarget.dataset.thumbnailFilename
  }
}
