import { Controller } from 'stimulus'
import vjs from 'video.js'

export default class extends Controller {
  static targets = ['code', 'video', 'fromInput', 'toInput', 'sliceContainer', 'newSlice', 'createRange']

  copy() {
    this.codeTarget.select()
    document.execCommand("copy")
    alert("Code copied to clipboard")
  }

  updateVideoOptions(e) {
    const param = e.target.id

    let url = this.getUrl()

    if (e.target.checked) {
      url.searchParams.set(param, '1')
    }
    else {
      url.searchParams.delete(param)
    }

    this.updateVideoURLAndCodeTarget(url)
  }

  updateSlices(e) {
    if (e.target.checked) {
      this.videoTarget.classList.remove('hide')
    }
    else {
      this.videoTarget.classList.add('hide')
    }
  }

  upsertRange() {
    const activeSlice = document.querySelector(".slice.active")

    activeSlice.classList.contains('new-slice') ? this.createRange() : this.updateRange(activeSlice)

    this.highlightSlice(this.newSliceTarget)
  }

  createRange() {
    let url = this.getUrl()

    const existingSettings = url.searchParams.get('videosettings')
    const inputPosition = this.inputPosition()
    const newRange = inputPosition.join('-')
    const newSettings = existingSettings ? `${existingSettings}_${newRange}` : newRange

    url.searchParams.set('videosettings', newSettings)

    this.updateVideoURLAndCodeTarget(url)

    const element = document.createElement("div")
    element.innerHTML = `<div class="slice bg-sky-900 text-white p-2 md:p-4 rounded-lg flex justify-between items-center mb-1"
                              data-range-slider-target="slice"
                              data-start="${inputPosition[0]}"
                              data-end="${inputPosition[1]}"
                              data-position="${document.querySelectorAll('.slice').length}"
                              data-action="click->share-video#highlight click->range-slider#update">
                           <div class="slice-info flex flex-col space-y-2 items-start">
                             <span><b>Starts:</b> <span class="slice-start">${inputPosition[0]}</span> secs</span>
                             <span><b>Ends:</b> <span class="slice-end">${inputPosition[1]}</span> secs</span>
                             <span><b>Duration:</b> <span class="slice-duration">${inputPosition[1] - inputPosition[0]}</span> secs</span>
                           </div>
                           <div class="flex space-x-4">
                             <button title="Remove" class="action-button hover:text-gray-300" data-action="click->share-video#removeRange">
                               <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                 <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"></path>
                               </svg>
                             </button>
                           </div>
                         </div>`

    this.newSliceTarget.before(element)
  }

  updateRange(slice) {
    let url = this.getUrl()

    const existingSettings = url.searchParams.get('videosettings')
    const ranges = existingSettings.split('_')
    const inputPosition = this.inputPosition()
    const newRange = inputPosition.join('-')
    ranges[slice.dataset.position - 1] = newRange

    url.searchParams.set('videosettings', ranges.join('_'))

    this.updateVideoURLAndCodeTarget(url)
  }

  removeRange(e) {
    let url = this.getUrl()
    const segmentCount = this.sliceContainerTarget.querySelectorAll('.slice').length;
    const slice = e.target.closest('.slice')

    if (segmentCount === 2) {
      url.searchParams.delete('videosettings')
    }
    else {
      const existingSettings = url.searchParams.get('videosettings')
      const ranges = existingSettings.split('_')

      ranges.splice(slice.dataset.position - 1, 1)
      const newRanges = ranges.join('_')

      url.searchParams.set('videosettings', newRanges)
    }

    slice.remove()

    this.updateVideoURLAndCodeTarget(url)
    this.highlightSlice(this.newSliceTarget)
  }

  getUrl() {
    this.src = this.codeTarget.innerHTML.match(/src="(.*)"/)[1]

    return new URL(this.decodeHtml(this.src));
  }

  decodeHtml(html) {
    const element = document.createElement("textarea");
    element.innerHTML = html;

    return element.value;
  }

  inputPosition() {
    const from = parseInt(this.fromInputTarget.value, 10)
    const to = parseInt(this.toInputTarget.value, 10)
    return [from, to]
  }

  highlight(e) {
    this.highlightSlice(e.target.closest('.slice'))
  }

  highlightSlice(element) {
    for (let slice of document.querySelectorAll('.slice')) {
      slice.classList.remove('active', 'bg-yellow-400', 'border-2', 'border-yellow-500')
      slice.classList.add('bg-gray-200', 'text-gray-700')
    }

    element.classList.add('active', 'bg-yellow-400', 'border-2', 'border-yellow-500')

    this.createRangeTarget.innerHTML =  element.classList.contains('new-slice') ? 'Create slice' : 'Update slice'
  }

  playVideo() {
    vjs(document.querySelector('video')).play()
  }

  updateVideoURLAndCodeTarget(url) {
    this.codeTarget.innerHTML = this.codeTarget.innerHTML.replace(this.src, url.href)

    return url
  }
}
