import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["answer"]

  toggleAnswer(event) {
    const answerElement = event.currentTarget
    const input = answerElement.querySelector("input[name*='selected']")
    const isSelected = input.value === 'true'

    // Toggle the selection
    input.value = isSelected ? 'false' : 'true'

    // Update the visual style
    if (isSelected) {
      answerElement.classList.remove('bg-sh-light-yellow', 'bg-opacity-50', 'border-sh-light-yellow', 'hover:bg-sh-light-yellow');
      answerElement.classList.add('hover:bg-gray-50')
    } else {
      answerElement.classList.remove('hover:bg-gray-50');
      answerElement.classList.add('bg-sh-light-yellow', 'bg-opacity-50', 'border-sh-light-yellow', 'hover:bg-sh-light-yellow');
    }
  }
}